import styled from "styled-components";

const PageStyle = styled.div`

overflow-x:hidden;

    .letter{
    font-family: evafiya;}
    position: absolute;
    width:100%;
    left: 0;
    top: 0;
`

export default PageStyle